<template>
  <div>
    <Header />
    <!-- <ListDappsSearch />  -->
    <ListDappsSearchOptimize />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
// import ListDappsSearch from '@/components/ListDappsSearch.vue'
import Footer from "../components/Footer.vue";
import ListDappsSearchOptimize from "../components/ListDappsSearchOptimize.vue";

export default {
  name: "Search",
  components: {
    Header,
    // ListDappsSearch,
    Footer,
    ListDappsSearchOptimize,
  },
  created() {
    this.$mixpanel.track("Visit Search");
  },
};
</script>
