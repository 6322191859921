<template>
  <div class="bg-isometric">
    <section class="main-search-header style-border">
      <div class="container px-md-3 px-lg-3 px-0">
        <div
          class="
            bg-light
            d-flex
            justify-content-start
            gap-3
            align-items-center
            py-3
            rounded-10
            px-3
          "
        >
          <div class="text-nowrap fw-bold">Trending dApps:</div>
          <marquee behavior="" direction="">
            <div class="d-flex justify-content-start gap-4 align-items-center">
              <div v-for="(trending, index) in dappsTrending" :key="index">
                <router-link
                  :to="'/dapps-detail/' + trending.slug"
                  class="text-white text-decoration-none"
                >
                  <span class="text-secondary"> {{ index + 1 }}. </span>
                  {{ trending.name }}
                </router-link>
              </div>
            </div>
          </marquee>
        </div>
        <div class="content-search px-3 px-md-0 px-lg-0">
          <div class="w-100 form-group with_icon">
            <div class="input_group">
              <input
                type="search"
                class="form-control rounded-pill"
                v-model="searchQuery"
                placeholder="Search anything ..."
              />
              <div class="icon">
                <i class="ri-search-2-line"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- {{resultQuery}} -->
        <!-- <span class="nav-item" v-for="(product, index) in resultQuery" :key="index">
              {{product.name}}
            </span> -->
        <ul
          class="
            nav nav-pills nav-tab-search
            no-wrap
            pl-4
            justify-content-start
          "
          style="
            overflow-x: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap !important;
          "
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              @click="selected = 'All'"
              :class="['nav-link', { active: selected === 'All' }]"
              id="pills-all-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-all"
              type="button"
              role="tab"
              aria-controls="pills-all"
              aria-selected="true"
            >
              Search
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
            v-for="(category, index) in listCategory"
            :key="index"
          >
            <button
              @click="
                selected = category.name;
                changeSlug(category.slug);
              "
              :class="[
                'nav-link text-nowrap',
                { active: selected === category.name },
              ]"
              :id="'pills-' + category.name + '-tab'"
              data-bs-toggle="pill"
              :data-bs-target="'#pills-' + category.name"
              type="button"
              role="tab"
              :aria-controls="'pills-' + category.name"
              aria-selected="true"
            >
              {{ category.name }}
            </button>
          </li>
        </ul>
      </div>
    </section>
    <section class="discover-NFTs-gradual pt-3" style="padding: 16px">
      <div class="container px-md-3 px-lg-3 px-0">
        <div class="tab-content" id="pills-tabContent">
          <!-- Result For Search Query Tabs -->
          <div
            :class="[
              'tab-pane fade',
              { show: selected === 'All' },
              { active: selected === 'All' },
            ]"
            id="#pills-all"
            role="tabpanel"
            aria-labelledby="pills-all-tab"
          >
            <section class="unList-bestSeller">
              <div class="content-list-sellers p-0">
                <ul class="nav">
                  <li class="nav-item w-100 position-relative">
                    <router-link
                      :to="'/dapps-detail/' + dappsAds.slug"
                      class="
                        nav-link
                        item-user-seller
                        bg-white
                        card-custom
                        rainbow
                      "
                      data-label="Ads"
                    >
                      <div class="item-user-img" style="padding-right: 0px">
                        <picture>
                          <source
                            :srcset="
                              !dappsAds.icon_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                  dappsAds?.icon_url
                                : dappsAds?.icon_url
                            "
                            @error="
                              () =>
                                (dappsAds.icon_url = '/images/no-images.png')
                            "
                            type="image/webp"
                          />
                          <img
                            class="avt-img-small"
                            :src="
                              !dappsAds.icon_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                  dappsAds?.icon_url
                                : dappsAds?.icon_url
                            "
                            @error="
                              () =>
                                (dappsAds.icon_url = '/images/no-images.png')
                            "
                            alt=""
                          />
                        </picture>
                        <div class="txt-user">
                          <p>
                            {{ dappsAds.name }}
                            <i class="ri-checkbox-circle-fill"></i>
                          </p>
                          <span
                            class="
                              text-dark
                              d-flex
                              flex-wrap
                              justify-content-start
                              align-items-center
                              gap-2
                            "
                          >
                            <span
                              v-for="(network, index) in dappsAds.networks"
                              :key="index"
                              class="d-inline-block"
                            >
                              <img
                                :src="network.logo_url"
                                width="20"
                                height="20"
                                alt=""
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="other-option">
                        <button type="button" class="btn btn-box-check">
                          <img src="/images/icons/ads.svg" alt="" />
                        </button>
                      </div>
                    </router-link>
                  </li>

                  <li
                    class="nav-item w-100"
                    v-for="(product, index) in resultQuery"
                    :key="index"
                  >
                    <router-link
                      :to="'/dapps-detail/' + product.slug"
                      class="nav-link item-user-seller bg-white"
                    >
                      <div class="item-user-img" style="padding-right: 0px">
                        <picture>
                          <source
                            :srcset="
                              !product.icon_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                  product?.icon_url
                                : product?.icon_url
                            "
                            @error="
                              () => (product.icon_url = '/images/no-images.png')
                            "
                            type="image/webp"
                          />
                          <img
                            class="avt-img-small"
                            :src="
                              !product.icon_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                  product?.icon_url
                                : product?.icon_url
                            "
                            @error="
                              () => (product.icon_url = '/images/no-images.png')
                            "
                            alt=""
                          />
                        </picture>
                        <div class="txt-user">
                          <p>
                            {{ product.name }}
                            <i class="ri-checkbox-circle-fill"></i>
                          </p>
                          <span
                            class="
                              text-dark
                              d-flex
                              flex-wrap
                              justify-content-start
                              align-items-center
                              gap-2
                            "
                          >
                            <span
                              v-for="(network, index) in product.networks"
                              :key="index"
                              class="d-inline-block"
                            >
                              <img
                                :src="network.logo_url"
                                width="20"
                                height="20"
                                alt=""
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="other-option">
                        <button type="button" class="btn btn-box-check">
                          <input type="checkbox" checked />
                          <div class="icon-box">
                            <i class="ri-add-fill"></i>
                          </div>
                        </button>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <!-- Result for tab category -->
          <div
            v-for="(category, index) in listCategory"
            :key="index"
            :class="[
              'tab-pane fade',
              { show: selected === category.name },
              { active: selected === category.name },
            ]"
            :id="'#pills-' + category.name"
            role="tabpanel"
            :aria-labelledby="'pills-' + category.name + '-tab'"
          >
            <section class="unList-bestSeller">
              <div class="content-list-sellers p-0">
                <ul class="nav flex-column">
                  <li
                    class="nav-item w-100 position-relative"
                    v-if="dappsByCategory !== undefined"
                  >
                    <div
                      class="
                        nav-link
                        item-user-seller
                        bg-white
                        card-custom
                        rainbow
                      "
                      data-label="Ads"
                    >
                      <router-link
                        :to="'/dapps-detail/' + adsByCategory.slug"
                        class="item-user-img"
                        style="padding-right: 0px"
                      >
                        <picture>
                          <source
                            :srcset="
                              !adsByCategory.icon_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                  adsByCategory?.icon_url
                                : adsByCategory?.icon_url
                            "
                            @error="
                              () =>
                                (adsByCategory.icon_url =
                                  '/images/no-images.png')
                            "
                            type="image/webp"
                          />
                          <img
                            class="avt-img-small"
                            :src="
                              !adsByCategory.icon_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                  adsByCategory?.icon_url
                                : adsByCategory?.icon_url
                            "
                            @error="
                              () =>
                                (adsByCategory.icon_url =
                                  '/images/no-images.png')
                            "
                            alt=""
                          />
                        </picture>
                        <div class="txt-user">
                          <p>
                            {{ adsByCategory.name }}
                            <i class="ri-checkbox-circle-fill"></i>
                          </p>
                          <span
                            class="
                              text-dark
                              d-flex
                              flex-wrap
                              justify-content-start
                              align-items-center
                              gap-2
                            "
                          >
                            <span
                              v-for="(network, index) in adsByCategory.networks"
                              :key="index"
                              class="d-inline-block"
                            >
                              <img
                                :src="network.logo_url"
                                width="20"
                                height="20"
                                alt=""
                              />
                            </span>
                          </span>
                        </div>
                      </router-link>
                      <div class="other-option">
                        <button type="button" class="btn btn-box-check">
                          <img src="/images/icons/ads.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item w-100 position-relative" v-else>
                    <router-link
                      to="/advertise"
                      class="
                        nav-link
                        item-user-seller
                        bg-white
                        card-custom
                        rainbow
                      "
                      data-label="Ads"
                    >
                      <div class="item-user-img" style="padding-right: 0px">
                        <picture>
                          <source
                            srcset="/images/no-ads.png"
                            @error="() => '/images/no-ads.png'"
                            type="image/webp"
                          />
                          <img
                            class="avt-img-small"
                            src="/images/no-ads.png"
                            @error="() => '/images/no-ads.png'"
                            alt=""
                          />
                        </picture>
                        <div class="txt-user">
                          <p>
                            Ads Here
                            <i class="ri-checkbox-circle-fill"></i>
                          </p>
                          <span
                            class="
                              text-dark
                              d-flex
                              flex-wrap
                              justify-content-start
                              align-items-center
                              gap-2
                            "
                          >
                            <small class="text-sm"
                              >Boost your dApps visitor</small
                            >
                          </span>
                        </div>
                      </div>
                      <div class="other-option">
                        <button type="button" class="btn btn-box-check">
                          <img src="/images/icons/ads.svg" alt="" />
                        </button>
                      </div>
                    </router-link>
                  </li>

                  <li
                    class="nav-item"
                    v-for="(product, index) in listProduct.data"
                    :key="index"
                  >
                    <router-link
                      :to="'/dapps-detail/' + product.slug"
                      class="nav-link item-user-seller bg-white"
                    >
                      <div class="item-user-img">
                        <picture>
                          <source
                            :srcset="
                              !product.icon_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                  product?.icon_url
                                : product?.icon_url
                            "
                            @error="
                              () => (product.icon_url = '/images/no-images.png')
                            "
                            type="image/webp"
                          />
                          <img
                            class="avt-img-small"
                            :src="
                              !product.icon_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                  product?.icon_url
                                : product?.icon_url
                            "
                            @error="
                              () => (product.icon_url = '/images/no-images.png')
                            "
                            alt=""
                          />
                        </picture>
                        <div class="txt-user">
                          <p>
                            {{ product.name }}
                            <i class="ri-checkbox-circle-fill"></i>
                          </p>
                          <span
                            class="
                              text-dark
                              d-flex
                              flex-wrap
                              justify-content-start
                              align-items-center
                              gap-2
                            "
                          >
                            <span
                              v-for="(network, index) in product.networks"
                              :key="index"
                              class="d-inline-block"
                            >
                              <img
                                :src="network.logo_url"
                                width="20"
                                height="20"
                                alt=""
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="other-option">
                        <button type="button" class="btn btn-box-check">
                          <input type="checkbox" checked />
                          <div class="icon-box">
                            <i class="ri-add-fill"></i>
                          </div>
                        </button>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
              <Pagination
                class="d-flex justify-content-center"
                :data="listProduct"
                :limit="2"
                :show-disabled="true"
                @pagination-change-page="getProducts"
              >
                <template #prev-nav>
                  <span>&lt;</span>
                </template>
                <template #page-nav> </template>
                <template #next-nav>
                  <span>&gt;</span>
                </template>
              </Pagination>
            </section>
          </div>
        </div>
      </div>

      <!-- lds-spinner -->
      <!-- <div class="loader-items">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div> -->
    </section>
  </div>
</template>
  
<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "ListDappsSearchOptimize",
  data() {
    return {
      listCategory: [],
      listProduct: {},
      listProductSearch: [],
      dappsAds: [],
      selected: "All",
      searchQuery: null,
      slug: "defi",
      adsByCategory: [],
      dappsTrending: [],
    };
  },
  components: {
    Pagination: LaravelVuePagination,
  },
  mounted() {
    this.getCategory();
    this.getAds();
    this.getTrendingDapps();
  },
  created() {
    this.getProduct();
    this.getProducts(this.page);
  },
  methods: {
    changeSlug(slug) {
      this.slug = slug;
      this.getProducts();
      this.getAdsByCategory(slug);
    },
    getCategory() {
      axios
        .get(process.env.VUE_APP_API + "api/category/list")
        .then((response) => (this.listCategory = response.data.data))
        .catch((e) => {
          console.log("Uncaught Error" + e);
        });
    },
    getProduct() {
      axios
        .get(process.env.VUE_APP_API + "api/product/getlist")
        .then((response) => (this.listProductSearch = response.data.data))
        .catch((e) => {
          console.log("Uncaught Error" + e);
        });
    },
    getProducts(page = 1) {
      axios
        .get(
          process.env.VUE_APP_API +
            "api/product/category/" +
            this.slug +
            "?page=" +
            page
        )
        .then((response) => {
          this.listProduct = response.data.data.products;
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((e) => {
          console.log("Uncaught Error" + e);
        });
    },
    async getAds() {
      try {
        await axios
          .get(process.env.VUE_APP_API + "api/product/ads-item")
          .then((response) => {
            this.dappsAds = response.data.data[0];
            // console.log(response.data.data[0]);
          });
      } catch (e) {
        console.log("Caught Error");
      }
    },
    async getAdsByCategory(slugCate) {
      try {
        await axios
          .get(process.env.VUE_APP_API + "api/product/ads/" + slugCate)
          .then((response) => {
            this.adsByCategory = response.data.data.products[0];
            console.log(response.data.data.products[0]);
            // console.log(response.data.data[0]);
          });
      } catch (e) {
        console.log("Caught Error");
      }
    },
    getTrendingDapps() {
      axios
        .get(process.env.VUE_APP_API + "api/product/trending-search")
        .then((res) => {
          this.dappsTrending = res.data.data;
          // console.log("This is trending data on search: ", res.data.data);
        });
    },
  },
  computed: {
    resultQuery() {
      if (this.searchQuery == null) {
        return this.listProductSearch.slice(0, 10);
      } else {
        const products = this.listProductSearch.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v));
        });
        return products.slice(0, 10);
      }
    },
  },
};
</script>
  
<style>
#pills-tab::-webkit-scrollbar {
  display: none;
}

.page-item.active .page-link .sr-only {
  display: none;
}
.card-custom::before {
  position: absolute;
  top: -3px;
  right: 3px;
  content: "";
  background: #04a759;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.card-custom::after {
  position: absolute;
  content: attr(data-label);
  border-radius: 4px;
  top: -15px;
  right: -1px;
  padding: 0.2rem;
  width: 6rem;
  background: #059650;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  box-shadow: 4px 4px 15px rgb(26 35 126 / 20%);
}

.bg-isometric {
  background-color: #14151c;
  opacity: 1;
  background: repeating-linear-gradient(
    45deg,
    #1f2125,
    #1f2125 20px,
    #14151c 20px,
    #14151c 100px
  );
}

.page-item.active .page-link {
  background-color: #14151c !important;
}
</style>
  